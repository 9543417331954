import React from 'react';

const BannerSection = () => {
  return (
    <section className="banner">
      <a href="https://www.sonyliv.com/sports/volleyball-rupay-prime-volleyball-league-1700001385">
        <img src="banner.jpg" alt="" style={{width:"100%"}} />
      </a>
    </section>
  );
};

export default BannerSection;